import React from 'react';
import { LoginRegister } from '@/common/components/login-register';
import { jsx as ___EmotionJSX } from "@emotion/core";

var LoginPage = function LoginPage() {
  return ___EmotionJSX(LoginRegister, {
    title: "login",
    isRegister: false
  });
};

LoginPage.displayName = "LoginPage";
export default LoginPage;